<template>
  <div class="home p-6 text-center text-lg">
    Be-On Analytics Zeigt dir deine Seiten perfomance.
    <a
      href="/dd"
      class="inline-block px-6 py-2.5 bg-blue-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-500 hover:shadow-lg focus:bg-blue-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-600 active:shadow-lg transition duration-150 ease-in-out"
      >zum Dashboard</a
    >
  </div>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
};
</script>
<style lang="scss" scoped></style>
