<template>
  <div>
    <h3>Seitenübersicht</h3>
    <div class="chart" id="pageper-1"></div>
  </div>
</template>

<script>
import { TabulatorFull as Tabulator } from "tabulator-tables";

export default {
  name: "TopSeiten",
  props: {
    data: Object,
  },
  data() {
    return {
      table: undefined,
    };
  },
  watch: {
    data: function () {
      this.update();
    },
  },
  mounted() {
    this.update();
  },
  methods: {
    update() {
      var tabledata = [];

      Object.values(this.data.slice(0, 12)).forEach(function (v) {
        if (v.label != "Unknown") {
          tabledata.push({
            Name: v.label,
            "Bounce Rate": v.bounce_rate,
            Besuche: v.nb_visits,
            "Durschnittliche Ladezeit": v.avg_time_generation,
          });
        }
      });

      var table =
        this.table != undefined
          ? this.table
          : new Tabulator("#pageper-1", {
              data: tabledata,
              autoColumns: true,
            });
      table.data = tabledata;
      this.table = table;
    },
  },
};
</script>
<style>
@import "tabulator-tables";
</style>

<style>
.tabulator-col {
  background-color: #0096d3;
}
</style>
