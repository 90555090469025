<template>
  <div
    id="sbox1"
    class="block text-xl text-gray-800 flex flex-row gap-4 w-full justify-center mt-4"
  >
    <div class="bg-gray-400 p-4 rounded-md">
      <label for="period" class="font-semibold pr-4">Zeitrahmen</label>
      <select
        name="period"
        id="period"
        selected="month"
        @change="l()"
        v-model="period"
      >
        <option value="month">Monat</option>
        <option value="year">Jahr</option>
      </select>
    </div>
    <div class="bg-gray-400 p-4 rounded-md">
      <label for="month" class="font-semibold pr-4">Monat</label>
      <input
        type="month"
        name="month"
        id="month"
        v-model="month"
        @change="l()"
      />
    </div>
  </div>

  <div class="defaultdash grid place-items-center w-full">
    <Spinner v-if="isloading"></Spinner>
    <DefaultDashboard :pagedata="pagedata" :month="month"></DefaultDashboard>
  </div>
</template>

<script>
import { fetchpagedata } from "@/api";
import DefaultDashboard from "@/components/DefaultDashboard.vue";
import moment from "moment";
import Spinner from "@/components/Spinner.vue";

export default {
  name: "DefaultDashboardView",
  components: {
    DefaultDashboard,
    Spinner,
  },
  async created() {
    await this.l();
  },
  data() {
    return {
      pagedata: undefined,
      isloading: true,
      isdone: false,
      period: "month",
      month: moment().subtract(1, "months").startOf("month").format("yyyy-MM"),
    };
  },
  methods: {
    async l() {
      if (this.$store.state.jwt == "") {
        this.$router.push("/login");
      }
      fetchpagedata(
        this.$store.state.pid,
        this.$store.state.jwt,
        this.period,
        this.month
      ).then((r) => {
        const k = JSON.parse(r);
        this.pagedata = k;
        this.isloading = false;
      });
    },
  },
};
</script>

<style scoped></style>

//TODO: disable invalid month inputs.
