var colors = [
  "#0096d3",
  "#1990D3",
  "#2B98D6",
  "#45A6DB",
  "#5AAFE0",
  "#76BDE5",
  "#90C9E9",
  "#9CD0EB",
  "#BCDEF3",
  "#D6EEFD",
];

var colors_variant2 = [
  "#102542",
  "#F87060",
  "#CDD7D6",
  "#B3A394",
  "#75B9BE",
  "#1B2A41",
  "#FF69EB",
  "#FFDC5E",
  "#FFA3A5",
  "#FFBF81",
  "#FF86C8",
];
export { colors, colors_variant2 };
