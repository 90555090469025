<template>
  <div>
    <h3>Meistbesuchte Seiten</h3>
    <canvas class="chart" id="topseiten-chart1"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

export default {
  name: "TopSeiten",
  props: {
    data: Object,
  },
  data() {
    return {
      chart: undefined,
    };
  },
  watch: {
    data: function () {
      this.chart.destroy();
      this.update();
    },
  },
  mounted() {
    this.update();
  },
  methods: {
    update() {
      const ctx = document.getElementById("topseiten-chart1");
      var chartd = {
        type: "bar",
        data: {
          labels: undefined,
          datasets: [
            {
              label: "Besuche",
              data: undefined,
              backgroundColor: "#1990D3",
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      };

      var labels = [];
      var data = [];

      Object.values(this.data.slice(0, 10)).forEach(function (v) {
        if (v.label != "Unknown") {
          data.push(v.nb_visits);
          labels.push(v.label);
        }
      });
      chartd.data.datasets[0].data = data;
      chartd.data.labels = labels;

      this.chart = new Chart(ctx, chartd);
      this.chart.canvas.parentNode.style.width = "40vw";
    },
  },
};
</script>
<style></style>
