<template>
  <div v-if="pagedata != undefined" class="w-5/6 text-xl text-gray-800">
    <h1 class="font-semibold text-3xl mb-2">{{ pagedata.pagename.name }}</h1>
    <div class="text-center mt-8 flex justify-center">
      <div class="flex justify-center text-center bg-gray-50" id="kpisection">
        <p class="m-2 rounded-sm hover:bg-gray-200 p-2">
          <b>Besuche</b> <br />
          {{ pagedata.kpi.visits }}
        </p>
        <p class="m-2 rounded-sm hover:bg-gray-200 p-2">
          <b>Seitenansichten </b><br />
          {{ pagedata.kpi.pageviews }}
        </p>
        <p class="m-2 rounded-sm hover:bg-gray-200 p-2">
          <b>ø Aufenthaltsdauer </b> <br />
          {{ pagedata.kpi.avg_time }}s
        </p>
        <p class="m-2 rounded-sm hover:bg-gray-200 p-2">
          <b>ø Aktionen/Besuch </b><br />
          {{ Math.round(pagedata.kpi.actions_per_visit * 100) / 100 }}
        </p>
        <p class="m-2 rounded-sm hover:bg-gray-200 p-2">
          <b>Absprungrate </b><br />
          {{ pagedata.kpi.bounce_rate }}
        </p>
      </div>
    </div>

    <h2 class="text-2xl font-semibold p-2">1. Besucher</h2>
    <div class="section sectionrow justify-center m" id="besuchersection">
      <BesucherEntwicklung
        :data="pagedata.get_users_over_past_months_basic"
      ></BesucherEntwicklung>
    </div>

    <h2 class="text-2xl font-semibold p-2">2. Orte</h2>
    <p>Die folgenden Graphen zeigen die Besuche nach Herkunft.</p>
    <div class="flex row flex-wrap items-start justify-around" id="ortesection">
      <Regionen :data="pagedata.get_regions"></Regionen>
      <Staedte :data="pagedata.getCityActions"></Staedte>
    </div>
    <h2 class="text-2xl font-semibold p-2">3. Geräte</h2>
    <div
      class="flex row flex-wrap items-start justify-around"
      id="geratesection"
    >
      <Gerate :data="pagedata.get_devices_type_basic"></Gerate>
    </div>
    <h2 class="text-2xl font-semibold p-2">5. Seiten</h2>
    <div class="flex row flex-wrap items-start justify-around" id="pagesection">
      <TopSeiten :data="pagedata.multi_pages_get_all_basic"></TopSeiten>
      <EinstiegsSeiten :data="pagedata.get_entry_pages"></EinstiegsSeiten>
      <Verweise :data="pagedata.get_referrers_all_channels"></Verweise>
      <PageTable :data="pagedata.multi_pages_get_all_basic"></PageTable>
    </div>
    <h2 class="text-2xl font-semibold p-2">6. Kanäle</h2>
    <div
      class="flex row flex-wrap items-start justify-around"
      id="channelsection"
    >
      <KanaleTyp :data="pagedata.get_referrers_type_basic"></KanaleTyp>
      <KanalPerformance
        :data="pagedata.get_referrers_type_basic"
      ></KanalPerformance>
    </div>
  </div>
</template>
<script>
import BesucherEntwicklung from "./charts/BesucherEntwicklung.vue";
import Regionen from "./charts/Regionen.vue";
import Staedte from "./charts/Staedte.vue";
import Gerate from "./charts/Geraete.vue";
import TopSeiten from "./charts/TopSeiten.vue";
import EinstiegsSeiten from "./charts/EinstiegsSeiten.vue";
import Verweise from "./charts/Verweise.vue";
import KanaleTyp from "./charts/KanaleTyp.vue";
import KanalPerformance from "./charts/KanalPerformance.vue";
import PageTable from "./charts/PageTable.vue";

export default {
  name: "DefaultDashboard",
  props: {
    pagedata: Object,
    month: String,
  },
  components: {
    BesucherEntwicklung,
    Regionen,
    Staedte,
    Gerate,
    TopSeiten,
    EinstiegsSeiten,
    Verweise,
    KanaleTyp,
    KanalPerformance,
    PageTable,
  },
};
//TODO: replace kpi boxes with custom component
</script>

<style scoped>
@media all and (max-width: 800px) {
  .dd1 {
    width: 100vw;
    margin: 0px;
  }
}

.section {
  width: 100%;
  text-align: left;
}
</style>
<style>
.chart {
  width: 40vw;
  height: 40vh;
}
.wchar {
  width: 80vw;
}
</style>
