<template>
  <div class="loginview flex items-center justify-center max-h-full h-64">
    <div
      class="loginform container flex flex-col items-center text-gray-50 rounded-lg flex-wrap w-96 text-2xl bg-clip-content"
    >
      <input
        type="email"
        name="email"
        id="email"
        v-model="email"
        class="block w-3/4 px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-2 focus:text-gray-700 focus:bg-white focus:outline-none"
        placeholder="Email"
      />
      <input
        type="password"
        name="passwd"
        id="passwd"
        v-model="password"
        class="block w-3/4 px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-2 focus:text-gray-700 focus:bg-white focus:outline-none"
        placeholder="Passwort"
        v-on:keyup.enter="submit"
      />
      <input
        type="button"
        class="bg-blue-400 hover:bg-blue-300 w-3/4 text-center cursor-pointer text-gray-800 rounded-sm"
        value="Login"
        @click="submit"
      />
      <a
        class="text-red-500 font-semibold"
        href="https://be-on.de/analyticsissue"
        v-text="statusmsg"
      >
      </a>
    </div>
  </div>
</template>

<script>
import { loginapiurl } from "@/api";

export default {
  name: "LoginView",
  data: function () {
    return {
      email: "",
      password: "",
      statusmsg: "",
    };
  },
  methods: {
    submit: async function () {
      const data = { email: this.email, password: this.password };
      var response = await fetch(loginapiurl + "/login", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
      var responsetext = await response.json();
      if (responsetext.pid != 0) {
        this.$store.commit("setjwt", responsetext.token);
        this.$store.commit("setpid", responsetext.pid);

        this.$router.push("/dd");
      } else {
        this.statusmsg = "Error.";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.statusmsg {
  color: red;
}

.loginview {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>
