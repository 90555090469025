/* eslint-disable no-unused-vars */
const loginapiurl = "https://s26.be-on.dev/auth/v1/";
const dataapirurl = "https://s26.be-on.dev/data/v1/";
const reportapiurl = "https://s26.be-on.dev/reportm/v1/";

async function fetchpagedata(pid, jwt, period, date) {
  const d = await fetch(
    dataapirurl +
      "?pageID=" +
      pid +
      "&d=" +
      date +
      "-01&d2=" +
      date +
      "-30&creports=false&idr=true&period=" +
      period,
    {
      headers: new Headers({
        Authorization: "Bearer " + jwt,
      }),
    }
  );
  const dj = await d.json();
  return dj;
}

async function fetchpagedatawithdefinedurl(url, jwt) {
  const d = await fetch(url, {
    headers: new Headers({
      Authorization: "Bearer " + jwt,
    }),
  });
  const dj = await d.json();
  return JSON.parse(dj);
}

function fetchreport(url) {
  //TODO: Update method to fetch real report.
  return JSON.parse(
    '{"title":"Example Title","html": "Im letzten Monat hatte die Seite {{ pagedata.kpi.visits }} Besuche was wiederum einen leichen aufwärts Trend erkennen lässt.  <BesucherEntwicklung :data=\'pagedata.get_users_over_past_months_basic\'></BesucherEntwicklung>  Dies ist ein Testbericht Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam","pagedata_url": "https://s26.be-on.dev/data/v1/?pageID=129&d=2022-01-01&d2=2022-01-30&creports=false&idr=true&period=month"}'
  );
}

async function fetchreportlist(jwt) {
  const url = reportapiurl + "/getreports";
  const d = await fetch(url, {
    headers: new Headers({
      Authorization: "Bearer " + jwt,
    }),
  });
  const dj = await d.json();
  return JSON.parse(dj);
}

export {
  loginapiurl,
  dataapirurl,
  fetchpagedata,
  fetchpagedatawithdefinedurl,
  fetchreport,
  fetchreportlist,
};
