<template>
  <nav
    class="flex gap-8 text-3xl w-full justify-center align-text-bottom flex-wrap"
  >
    <router-link class="text-4xl font-bold pt-6 m-1" to="/">Home</router-link>
    <router-link class="text-4xl font-bold pt-6 m-1" to="/login"
      >Login</router-link
    >
    <router-link class="text-4xl font-bold pt-6 m-1" to="/dd"
      >Dashboard</router-link
    >
    <router-link class="text-4xl font-bold pt-6 m-1" to="/reports"
      >Reports</router-link
    >
  </nav>
  <router-view />
</template>

<style lang="scss">
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("./assets/Roboto/Roboto-Regular.ttf");
}
#app {
  font-family: "Roboto", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
}

nav {
  a {
    color: black;

    &.router-link-exact-active {
      color: #79c1e7;
    }
  }
}

textarea:focus,
input:focus {
  outline: none;
}
</style>
