<template>
  <div class="reportoverview text-gray-800 text-2xl text-center">
    <h1>Hier sind deine Reports.</h1>
    <div class="flex flex-row justify-center">
      <div class="flex flex-col gap-4">
        <div v-for="report in reportlist" :key="report.reportID" class="report">
          {{ report.title }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// TODO: USE api.js to fetch reports and put them in reportlist
import { fetchreportlist } from "@/api";

export default {
  name: "ReportOverview",
  data() {
    return {
      reportlist: Object,
    };
  },
  async created() {
    fetchreportlist(this.$store.state.jwt).then((d) => {
      this.reportlist = d;
    });
  },
};
</script>
