import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createStore } from "vuex";
import "./assets/main.css";

export const store = createStore({
  state() {
    return {
      jwt: "",
      pid: 0,
    };
  },
  mutations: {
    setjwt(state, token) {
      state.jwt = token;
    },
    setpid(state, pid) {
      state.pid = pid;
    },
  },
});

createApp(App).use(router).use(store).mount("#app");
