<template>
  <div>
    <h3>Entwicklung der Besucherzahlen</h3>
    <canvas class="chart" id="besucher-chart1"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);
export default {
  name: "BesucherEntwicklung",
  props: {
    data: Object,
  },
  data() {
    return {
      chart: undefined,
    };
  },
  watch: {
    data: function () {
      this.chart.destroy();
      this.update();
    },
  },
  methods: {
    update() {
      const ctx = document.getElementById("besucher-chart1");
      var chartd = {
        type: "bar",
        data: {
          labels: undefined,
          datasets: [
            {
              label: "Monat",
              data: undefined,
              backgroundColor: "#1990D3",
            },
          ],
        },
        options: {
          scales: {
            y: {
              type: "linear",
            },
          },
        },
      };

      var labels = [];
      var data = [];

      Object.keys(this.data).forEach(function (d) {
        labels.push(d);
      });
      Object.values(this.data).forEach(function (v) {
        data.push(v);
      });
      chartd.data.datasets[0].data = data;
      chartd.data.labels = labels;
      this.chart = new Chart(ctx, chartd);
      this.chart.canvas.parentNode.style.width = "40vw";
    },
  },
  mounted() {
    this.update();
  },
};
</script>
<style></style>
