<template>
  <div>
    <h3>Besuche nach Gerätetyp</h3>
    <canvas class="chart" id="gerate-chart1"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);
import { colors } from "@/assets/color_fallback";

export default {
  name: "Gerate",
  props: {
    data: Object,
  },
  watch: {
    data: function () {
      this.chart.destroy();
      this.update();
    },
  },
  data() {
    return {
      chart: undefined,
    };
  },
  mounted() {
    this.update();
  },
  methods: {
    update() {
      const ctx = document.getElementById("gerate-chart1");
      var chartd = {
        type: "pie",
        data: {
          labels: undefined,
          datasets: [
            {
              label: "Gerät",
              data: undefined,
              backgroundColor: undefined,
            },
          ],
        },
      };

      var labels = [];
      var data = [];
      this.data.forEach(function (d) {
        if (d.label != "Unknown" && d.nb_visits != 0) {
          labels.push(d.label);
          data.push(d.nb_visits);
        }
      });
      chartd.data.datasets[0].backgroundColor = colors;
      chartd.data.datasets[0].data = data;
      chartd.data.labels = labels;

      this.chart = new Chart(ctx, chartd);
      this.chart.canvas.parentNode.style.width = "40vh";
    },
  },
};
</script>
<style scoped></style>
